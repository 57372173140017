<template>
  <van-config-provider :theme-vars="themeVars">
    <van-nav-bar :title="$t('my_favorite')" :left-text="$t('back')" @click.left="$router.back()" />
    <div class="order_wrap">
      <van-tabs v-model:active="activeTab" class="my-2" @change="onRefresh">
        <van-tab :title="$t('favorited')" name="collect"></van-tab>
        <van-tab :title="$t('liked')" name="like"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('no_more')" class="list"
          @load="loadFavouriteList">
          <template v-for="(item, index) in list" :key="index">
            <van-card :thumb="item.screenshot">
              <template #title>
                <h6>{{ item.name }}</h6>
              </template>
              <template #desc>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="left">
                    <div class="van-multi-ellipsis--l2 text-muted">{{ stripTags(item.description) }}</div>
                  </div>
                  <van-button size="small" type="primary"
                    @click="$router.push({ name: 'TicketDetail', params: { id: item.id } })">{{ $t("check") }}</van-button>
                </div>
              </template>
            </van-card>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
  </van-config-provider>
</template>

<script>
import { reactive, toRefs } from "vue";
import { getMyFavoriteList } from "@/api/scenic.service";
import { time2date, stripTags } from "@/utils/util";
export default {
  setup() {
    const currentItem = reactive({});
    const state = reactive({
      page: 1,
      page_size: 10,
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      keyword: "",
      activeTab: "collect",
      showDetail: false,
    });

    const themeVars = {
      // tabsBottomBarColor: "#222",
      buttonPrimaryBackgroundColor: "#222",
      buttonPrimaryBorderColor: "#222",
      cardThumbSize: "66px",
    };

    const loadFavouriteList = async () => {
      try {
        const { result } = await getMyFavoriteList({
          type: state.activeTab,
          page: state.page,
          page_size: state.page_size,
        });
        if (result.items.length > 0) {
          state.list.push(...result.items);
          state.page++;
        }
        if (!result.hasNext || result.items.length < state.page_size) {
          state.finished = true;
        }
        state.refreshing = false;
        state.loading = false;
      } catch (error) {
        console.log(error);
      }
    };

    const onRefresh = () => {
      state.finished = false;
      state.loading = true;
      state.list = [];
      state.page = 1;
      loadFavouriteList();
    };

    return {
      ...toRefs(state),
      themeVars,
      currentItem,
      time2date,
      loadFavouriteList,
      onRefresh,
      stripTags,
    };
  },
};
</script>
<style lang="less" scoped>
.order_wrap {
  width: 100%;
  min-height: 100vh;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 0 20px;

  .list {
    min-height: 100vh;
    padding: 0;

    .van-card {
      border-radius: 15px;
      padding: 15px 10px;

      .left {
        max-width: 65%;
      }

      .van-button {
        border-radius: 15px;
        min-width: 60px;
      }
    }
  }
}
</style>
